var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { isApp } from '../util/deviceContext';
const useKillSwitch = () => {
    const [isKilled, setIsKilled] = useState(false);
    useEffect(() => {
        const checkKillSwitch = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield fetch('https://api.nomady.camp/misc/oldmady-app-killswitch');
                const data = yield response.json();
                setIsKilled(data.isKillSwitchEnabled);
            }
            catch (error) {
                // If request fails, default to not killed
                console.error('Failed to fetch kill switch status:', error);
                setIsKilled(false);
            }
        });
        if (isApp) {
            checkKillSwitch();
        }
    }, []);
    return isKilled;
};
export default useKillSwitch;
