import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { GIFT_CARD_SALE_TYPE } from '@nomady/shared/gift-card/config';
import { isBrowser } from '@nomady/shared/utils/env';
import GiftCardShopItem from './partials/GiftCardShopItem';
const Wrapper = styled.section `
  display: flex;
  flex-direction: column;
`;
const SectionGiftCardShop = () => {
    useEffect(() => {
        if (isBrowser() && !!(window === null || window === void 0 ? void 0 : window.fbq)) {
            console.info('track viewcontent');
            window.fbq('track', 'ViewContent');
        }
    }, []);
    return (_jsxs(Wrapper, { children: [_jsx(GiftCardShopItem, { type: GIFT_CARD_SALE_TYPE.DIGITAL_GIFT_CARD }), _jsx(GiftCardShopItem, { type: GIFT_CARD_SALE_TYPE.PHYSICAL_GIFT_CARD }), _jsx(GiftCardShopItem, { type: GIFT_CARD_SALE_TYPE.GIFT_CARD_BOX }), _jsx(GiftCardShopItem, { type: GIFT_CARD_SALE_TYPE.GIFT_CARD_BOX_CAMPER }), _jsx(GiftCardShopItem, { type: GIFT_CARD_SALE_TYPE.GIFT_CARD_BOX_ALL_IN_ONE }), _jsx(GiftCardShopItem, { type: GIFT_CARD_SALE_TYPE.CUP_ONLY }), _jsx(GiftCardShopItem, { type: GIFT_CARD_SALE_TYPE.SCARF_ONLY })] }));
};
export default SectionGiftCardShop;
