import { MarketplaceLocale } from '@nomady/shared/config';
export var CmsSlug;
(function (CmsSlug) {
    CmsSlug["TermsAndConditions"] = "terms-and-conditions";
    CmsSlug["Camper"] = "camper";
    CmsSlug["PrivacyPolicy"] = "privacy-notice";
    CmsSlug["Host"] = "host";
    CmsSlug["Fees"] = "fees";
    CmsSlug["GiftCardTerms"] = "gift-card-terms";
    CmsSlug["DiscountCodeTerms"] = "discount-code-terms";
    CmsSlug["Jobs"] = "jobs";
    CmsSlug["Contact"] = "contact";
    CmsSlug["Media"] = "media";
    CmsSlug["Home"] = "home";
    CmsSlug["NomadyPact"] = "nomady-pact";
    CmsSlug["Scout"] = "scout";
    CmsSlug["ConditionsOfParticipation"] = "conditions-of-participation";
    CmsSlug["LegalNotice"] = "legal-notice";
    CmsSlug["Partner"] = "partners";
    CmsSlug["PartnerTcs"] = "partner-tcs";
    CmsSlug["Vision"] = "vision";
    CmsSlug["AboutUs"] = "about-us";
    CmsSlug["BuyGiftCard"] = "buy-gift-card";
    CmsSlug["BuyGiftCardBusiness"] = "buy-gift-card-business";
    CmsSlug["GiftCardOrderConfirmation"] = "gift-card-order-confirmation";
    CmsSlug["Region"] = "region";
    CmsSlug["MarketingLenk"] = "marketing-lenk";
    CmsSlug["MarketingGoeppingen"] = "marketing-goeppingen";
    CmsSlug["MyCabin"] = "mycabin";
    CmsSlug["MyCabinCamper"] = "mycabin-camper";
    CmsSlug["MyCabinHosts"] = "mycabin-hosts";
    CmsSlug["MyCabinGuide"] = "mycabin-guide";
    CmsSlug["Fusion"] = "fusion";
    CmsSlug["HostFusion"] = "host-fusion";
    CmsSlug["Regions"] = "regions";
    CmsSlug["Inspirations"] = "inspirations";
    CmsSlug["PartnerMountainHardwear"] = "partner-mountain-hardwear";
    CmsSlug["PartnerSchlanser"] = "partner-schlanser";
})(CmsSlug || (CmsSlug = {}));
const CountryLocalizedCmsSlugs = [
    CmsSlug.Home,
    CmsSlug.Host,
    CmsSlug.TermsAndConditions,
    CmsSlug.Scout,
];
const NotFullyLocalizedCmsLocales = [
    MarketplaceLocale['de-AT'],
    MarketplaceLocale['de-DE'],
    MarketplaceLocale['fr-FR'],
    MarketplaceLocale['it-IT'],
];
const extendedPopulationSlugs = [
    CmsSlug.TermsAndConditions,
    CmsSlug.GiftCardTerms,
    CmsSlug.DiscountCodeTerms,
    CmsSlug.PrivacyPolicy,
    CmsSlug.NomadyPact,
];
const getLocaleFromRequestedLocale = (requestedLocale, slug) => {
    if (!NotFullyLocalizedCmsLocales.includes(requestedLocale) ||
        CountryLocalizedCmsSlugs.includes(slug)) {
        return requestedLocale;
    }
    const mainLanguage = requestedLocale.substring(0, 2);
    return `${mainLanguage}-CH`;
};
export const getCmsEndpoint = (endpoint, slug, requestedLocale) => {
    const locale = getLocaleFromRequestedLocale(requestedLocale, slug);
    // certain nested sections need deeper population to work properly (e.g. terms-of-conditions)
    const populateSettings = extendedPopulationSlugs.includes(slug)
        ? `deep,5`
        : 'deep';
    return `${endpoint}/api/pages?filters[slug][$eq]=${slug}&populate=${populateSettings}&locale=${locale}`;
};
export const getCmsRegionEndpoint = (endpoint, name, requestedLocale) => {
    const locale = getLocaleFromRequestedLocale(requestedLocale);
    return `${endpoint}/api/regions?filters[routeName][$eq]=${name}&populate=deep&locale=${locale}`;
};
export const getCmsRegionsEndpoint = (endpoint, requestedLocale) => {
    const locale = getLocaleFromRequestedLocale(requestedLocale);
    return `${endpoint}/api/regions?populate=deep&locale=${locale}`;
};
export const getCmsInspirationEndpoint = (endpoint, name, requestedLocale) => {
    const locale = getLocaleFromRequestedLocale(requestedLocale);
    return `${endpoint}/api/inspirations?filters[routeName][$eq]=${name}&populate=deep&locale=${locale}`;
};
export const getCmsInspirationsEndpoint = (endpoint, requestedLocale) => {
    const locale = getLocaleFromRequestedLocale(requestedLocale);
    return `${endpoint}/api/inspirations?populate=deep&locale=${locale}`;
};
