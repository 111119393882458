import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../Text/Text';
import AppStoreButton, { StoreType } from '../AppStoreButton/AppStoreButton';
import { useIntl } from 'react-intl';
import { ColorVariant } from '../../theme';
import { isIos } from '../../util/deviceContext';
import Logo from '../Logo/Logo';
import { useSelector } from 'react-redux';
import { safeSpaceTopSelector } from '../../slices/UISlice';
const KillSwitchOverlay = styled.div `
  position: fixed;
  top: ${props => props.safeSpaceTop}px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.color.basicSnowGrey};
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  text-align: center;
`;
const LogoWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: auto;
`;
const AppKillSwitch = () => {
    const intl = useIntl();
    const safeSpaceTop = useSelector(safeSpaceTopSelector);
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);
    return (_jsxs(KillSwitchOverlay, Object.assign({ safeSpaceTop: safeSpaceTop }, { children: [_jsx(LogoWrapper, { children: _jsx(Logo, {}) }), _jsxs(ContentWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H2SB, color: ColorVariant.FunctionStoneGreyInactive }, { children: intl.formatMessage({ id: 'AppKillSwitch.title' }) })), _jsx(Text, Object.assign({ variant: TextVariant.P3R, color: ColorVariant.FunctionStoneGreyInactive }, { children: intl.formatMessage({ id: 'AppKillSwitch.message' }) })), isIos ? (_jsx(AppStoreButton, { store: StoreType.AppStore })) : (_jsx(AppStoreButton, { store: StoreType.GooglePlay }))] })] })));
};
export default AppKillSwitch;
